html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  text-rendering: optimizeSpeed;
  overflow-wrap: break-word;
}

main {
  flex-grow: 1;
  overflow: hidden;
}

* {
  text-overflow: ellipsis;
  background-position: center;
  background-repeat: no-repeat;
}

ul {
  list-style: disc inside;
}

ul[role="list"] {
  list-style: none;
}

a {
  text-decoration-skip-ink: auto;
}

audio,
canvas,
iframe,
img,
svg,
video,
button,
input[type="button"],
input[type="submit"] {
  user-select: none;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"],
[disabled] {
  cursor: default;
}

[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

label {
  cursor: pointer;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}