@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-gray-900;
  }
}

@layer components {

}

@layer utilities {
  .container {
    @apply y mx-4;
  }

  @variants responsive {
    .x {
      @apply flex flex-row space-y-0;
    }

    .x-reverse {
      @apply flex flex-row-reverse space-x-reverse space-y-0;
    }

    .y {
      @apply flex flex-col space-x-0;
    }

    .y-reverse {
      @apply flex flex-col-reverse space-y-reverse space-x-0;
    }
  }

  .z-0 {
    z-index: 0;
  }

  .z-dropdown {
    z-index: 100;
  }

  .z-sticky {
    z-index: 200;
  }

  .z-fixed {
    z-index: 300;
  }

  .z-modal-backdrop {
    z-index: 400;
  }

  .z-modal {
    z-index: 500;
  }

  .z-popover {
    z-index: 600;
  }

  .z-tooltip {
    z-index: 700;
  }

  .list-checkmark > li:before {
    content: '✓';
    background-color: transparent;
    border-radius: 0;
    width: 1rem;
    height: 1rem;
    position: static;
    color: var(--orange);
  }

  .layer {
    @apply absolute left-0 right-0 top-0 bottom-0 w-full h-full;
  }

  .layers {
    @apply relative;
  }

  .layers > * {
    @apply absolute left-0 right-0 top-0 bottom-0 w-full h-full;
  }

  .visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }

  .visually-hidden.focusable:active,
  .visually-hidden.focusable:focus {
    clip: auto;
    clip-path: none;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    white-space: inherit;
  }

  .scroll-to {
    scroll-margin-top: 5rem;
  }
}
